import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import Section from '../Section/Section';
import AttentionIcon from '../../images/inline-svg/attention.svg';
import './_important-information.scss';
import { Heading3 } from '../Heading/Heading';
import Text from '../Text/Text';
import { COLOR, FONT_SIZE, MARGIN, BREAKPOINT } from '../../utils/constants';

class ImportantInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Section
        id={this.props.id}
        className={classnames('section--important-info', this.props.className)}
      >
        <div className="row">
          <div className="col-12">
            <section className="important-info">
              <header className="important-info__header">
                <StyledAttentionIcon />
                <ImportantInfoTitle>Important Information</ImportantInfoTitle>
              </header>
              <div className="row">
                {/* Display section one if it has content */}
                <div className={this.props.sectionOneColummClasses}>
                  {this.props.data.content.importantInformationSectionOne.map(
                    (line, i) => (
                      <Text
                        className="important-info__text"
                        key={i}
                        fontSize={FONT_SIZE.SMALL}
                        css={`
                          margin-bottom: ${MARGIN.M16};
                        `}
                      >
                        {ReactHTMLParser(line)}
                      </Text>
                    )
                  )}
                </div>
                {/* Display section two if it has content */}
                {this.props.data.content.importantInformationSectionTwo && (
                  <div className={this.props.sectionTwoColummClasses}>
                    {this.props.data.content.importantInformationSectionTwo.map(
                      (line, i) => (
                        <Text
                          className="important-info__text"
                          key={i}
                          fontSize={FONT_SIZE.SMALL}
                          css={`
                            margin-bottom: ${MARGIN.M16};
                          `}
                        >
                          {ReactHTMLParser(line)}
                        </Text>
                      )
                    )}
                    {/* Display additional payments disclaimer one if it exists */}
                    {this.props.additionalPaymentsDisclaimerOne && (
                      <Text
                        key="addPay1"
                        id="additional-payments"
                        className="important-info__text"
                        fontSize={FONT_SIZE.SMALL}
                        css={`
                          margin-bottom: ${MARGIN.M16};
                        `}
                      >
                        {ReactHTMLParser(
                          this.props.data.content
                            .additionalPaymentsDisclaimerOne
                        )}
                      </Text>
                    )}
                    {/* Display additional payments disclaimer two if it exists */}
                    {this.props.additionalPaymentsDisclaimerTwo && (
                      <Text
                        key="addPay2"
                        className="important-info__text"
                        fontSize={FONT_SIZE.SMALL}
                        css={`
                          margin-bottom: ${MARGIN.M16};
                        `}
                      >
                        {ReactHTMLParser(
                          this.props.data.content
                            .additionalPaymentsDisclaimerTwo
                        )}
                      </Text>
                    )}
                    {/* Display additional payments disclaimer three if it exists */}
                    {this.props.additionalPaymentsDisclaimerThree && (
                      <Text
                        key="addPay3"
                        className="important-info__text"
                        fontSize={FONT_SIZE.SMALL}
                        css={`
                          margin-bottom: ${MARGIN.M16};
                        `}
                      >
                        {ReactHTMLParser(
                          this.props.data.content
                            .additionalPaymentsDisclaimerThree
                        )}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </Section>
    );
  }
}

ImportantInformation.propTypes = {
  id: PropTypes.string,
  /** data object containing the inner content */
  data: PropTypes.shape({
    /** First column content */
    importantInformationSectionOne: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.element
    ]),
    /** Second column content */
    importantInformationSectionArray: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.element
    ]),
    additionalPaymentsDisclaimerOne: PropTypes.bool,
    additionalPaymentsDisclaimerTwo: PropTypes.bool
  }).isRequired,
  /** add additional css class to the container */
  className: PropTypes.string,
  /** add additional css class to the first column container */
  sectionOneColummClasses: PropTypes.string,
  /** add additional css class to the second column container */
  sectionTwoColummClasses: PropTypes.string
};

const StyledAttentionIcon = styled(AttentionIcon)`
  height: 34px;
  width: 34px;
  stroke: ${COLOR.BLACK};
  flex-shrink: 1;

  @media (min-width: ${BREAKPOINT.LG}) {
    height: 40px;
    width: 40px;
  }
`;

const ImportantInfoTitle = styled(Heading3)`
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 0 12px;
  color: ${COLOR.BLACK};
  font-weight: 600;

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 24px;
    margin: 0 0 0 24px;
  }
`;

export default ImportantInformation;
