/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { graphql, useStaticQuery } from 'gatsby';
import { marked } from 'marked';
import { StaticImage } from 'gatsby-plugin-image';
import { FeaturesSlider } from '@latitude/features-slider';
import PLData from '@/data/pages/personal-loan-to-go.json';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import HowToApply from '@latitude/how-to-apply';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import queryString from 'query-string';
import { Box } from '@latitude/box';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import Layout from '@/components/layout';
import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Heading4 } from '@/components/Heading/Heading';
import { List, ListItem } from '@/components/List';
import { Strong } from '@/components/Text/Bold';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '../../utils/constants';
import { parseContent } from '../../utils/helpers';
import footerData from '@/data/json/footer.json';
import HowToApplyListLoansToGo from '../HowToApplyLoansToGo/HowToApplyListLoansToGo';
import LfsHeader from '../Header/LfsHeader';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';
import { NotificationBanner } from '@latitude/banner';

// Shared eligibility criteria content
export const PLEligibilityCriteria = props => {
  const {
    isSecuredLoanOnly,
    isUnsecuredLoanOnly,
    customEligibilityCriteria
  } = props;
  const ListHeading = ({ children }) => {
    return (
      <Strong
        css={`
          display: block;
          margin: ${MARGIN.M8} 0;
        `}
      >
        {children}
      </Strong>
    );
  };

  // work out best rate section title
  let bestRateTitle = '';
  if (isSecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestSecuredRateTitle;
  } else if (isUnsecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestUnsecuredRateTitle;
  } else {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestRateTitle;
  }

  // work out eligibility criteria list data
  let eligibilityCriteria = [];
  if (
    Array.isArray(customEligibilityCriteria) &&
    customEligibilityCriteria.length
  ) {
    eligibilityCriteria = [...customEligibilityCriteria];
  } else {
    eligibilityCriteria = [...PLData.content.eligibilityCriteria];
  }

  // This is to ensure that personal loan page always scroll to top
  // get started - soft quote - exit timeout - no to stop scroll point at get started button
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Heading4
        color={COLOR.BLACK}
        marginBottom={MARGIN.M16}
        isResponsive={false}
      >
        Eligibility
      </Heading4>

      <ListHeading>{PLData.content.eligibilityCriteriaTitle}</ListHeading>
      <List>
        {eligibilityCriteria.map((item, index) => {
          return <ListItem key={index}>{parseContent(item) || ''}</ListItem>;
        })}
      </List>
    </React.Fragment>
  );
};

const PersonalLoanToGoPageContent = props => {
  let queryParams;
  if (typeof window !== 'undefined') {
    queryParams = queryString.parse(location.search);
  }

  if (queryParams && queryParams.partnername) {
    typeof window !== 'undefined' &&
      sessionStorage.setItem('partnername', queryParams.partnername);
  } else {
    typeof window !== 'undefined' && sessionStorage.removeItem('partnername');
  }

  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Loans2Go Benefits with a Latitude Personal Loan',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  const pageQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/src/templates/life-done-better/articles/*.md"
          }
          frontmatter: {
            title: {
              in: [
                "Understanding your credit score"
                "Get Personal: Choosing The Loan That's Right For You"
                "5 ways to improve your credit score"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              path
              thumbnail
            }
          }
        }
      }
    }
  `);

  return (
    <Layout
      location={props.location}
      metaDesc={PLData.metaDesc}
      title={PLData.title}
      canonical={props.location.href}
      customFooter={footerData}
      noHeader
      noBreadcrumb
    >
      <Box
        css={`
          .top-menu-items,
          .logon-button-wrapper,
          .search-button,
          .top-menu-items__overlay {
            display: none !important;
          }
        `}
      >
        <LfsHeader {...props} />
      </Box>
      <main
        className="navigation-spacer"
        css={`
          div[class*='SiteLinksstyles__Outer-'],
          .top-menu-items {
            display: none !important;
          }
        `}
      >
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {/* <Lframe personalLoans title={PLData.title} /> */}
          <HeroBranded
            css={`
              background-image: linear-gradient(180deg, #b5e4ef, #f8f8f8);
              > div > div:first-child {
                @media (max-width: ${BREAKPOINT.LG}) {
                  width: 47% !important;
                }
                @media (max-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;
                }
              }
              > div > div.HeroContent {
                @media (min-width: ${BREAKPOINT.MD}) {
                  width: 85% !important;
                  margin-right: 25px;
                }
                a.trackcustomanalytics {
                  width: auto;
                }
              }
            `}
            imageContent={
              <Box
                css={`
                  height: 250px;
                  width: auto;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    width: 300px;
                    margin: 80px auto;
                  }

                  @media (min-width: ${BREAKPOINT.MD}) {
                    display: flex;
                    height: 300px;
                  }

                  @media (min-width: ${BREAKPOINT.LG}) {
                    height: 395px;
                  }
                  .gatsby-image-wrapper img {
                    height: auto;
                    top: 115px;
                    @media (max-width: ${BREAKPOINT.MD}) {
                      top: 0;
                    }
                  }
                `}
              >
                <StaticImage
                  src="../../images/hero/loans-to-go-logo.webp"
                  alt="Personal Loans"
                  width={417}
                  quality={90}
                  placeholder="blurred"
                />
              </Box>
            }
            title={
              <>
                Latitude Personal Loans with Loans2Go
                <br />
              </>
            }
            text={
              <>
                Your Loans2Go Benefits Code has been registered. Apply today and
                receive $0 Establishment Fee along with a $250 eGift Mastercard
                for approved and settled applications. Check your interest rate
                in 2 minutes with no impact to your credit score.
                <br />
              </>
            }
            buttonProps={{
              href: applyUrl,
              children: 'Get Rate Estimate',
              trackId: 'hero-get-started',
              width: '220px'
            }}
            trackEventData={{
              category: 'cta',
              action: 'quote-link'
            }}
          />
          <div
            className="d-none d-lg-block"
            css={`
              position: relative;
              z-index: 2;
            `}
          >
            <StickyNavigation
              items={PLData.nav}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              trackProductId={['PLAULF-WEB']}
              offsetElem="[data-sticky-navigation-offset]"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
            />
          </div>
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="about-this-offer"
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
          />

          <EstimateRateWidgetSection
            id="check-your-rate"
            purpose="purpose"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />

          <div>
            <LoanRates
              isBranded
              rateBoxType="variableSymLEF"
              rateBoxType2="fixedSymLEF"
              fees={PLData.content.aboutTheLoan}
              extraTCs="true"
              determineRateLink
              css={`.loan-type{display:none;`}
            />
          </div>

          <div id="fees-charges">
            <FeesAndCharges data={PLData.content.feesAndChargesLEF1} />
          </div>

          <HowToApply
            id="how-to-apply"
            box1={<HowToApplyListLoansToGo variant="sym" />}
            box2={<PLEligibilityCriteria />}
          />

          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loanLoanToGo.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            additionalPaymentsDisclaimerLEF
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default PersonalLoanToGoPageContent;
